<template>
  <div class="history-list">
    <v-tabs
      v-model="selectedPanel"
      v-if="showTabs"
      grow
    >
      <v-tab>
        <span>{{$t('t.HistoryV6')}}</span>
      </v-tab>
      <v-tab>{{$t('t.HistoryV5')}}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="selectedPanel">
      <v-tab-item class="history-menu">
        <v-card
          v-if="!history.length && !processingQuery"
          elevation="0"
        >
          <v-card-text class="nowrap">
            {{$t('t.ThereIsNothingToSeeHere')}}
          </v-card-text>
        </v-card>
        <v-list
          :key="date"
          dense
          v-for="(entries, date, index) in groupedHistory"
        >
          <v-subheader class="nowrap">
            {{date}}
            <v-spacer v-if="canOpenSidePanel && !index" />
            <v-btn
              v-if="canOpenSidePanel && !index"
              @click="openInSidePanel"
              icon
              small
              class="background-plain ml-2"
              elevation=0
            >
              <v-icon small>{{$icon('i.OpenInSidePanel')}}</v-icon>
            </v-btn>
          </v-subheader>
          <history-line
            class="nowrap"
            :key="index"
            v-for="(entry, index) in entries"
            :item="entry"
          />
        </v-list>
        <v-btn
          v-if="nextPage && !processingQuery"
          @click="query(nextPage)"
          block
          elevation="0"
        >
          {{$t('t.More')}}...
        </v-btn>
      </v-tab-item>
      <v-tab-item>
        <v-card
          v-if="!historyV5.length && !processingQuery"
          elevation="0"
        >
          <v-card-text class="nowrap">
            {{$t('t.ThereIsNothingToSeeHere')}}
          </v-card-text>
        </v-card>
        <v-list
          :key="date"
          dense
          v-for="(entries, date, index) in groupedHistoryV5"
        >
          <v-subheader class="nowrap">
            {{date}}
            <v-spacer v-if="canOpenSidePanel && !index" />
            <v-btn
              v-if="canOpenSidePanel && !index"
              @click="openInSidePanel"
              icon
              small
              class="background-plain ml-2"
              elevation=0
            >
              <v-icon small>{{$icon('i.OpenInSidePanel')}}</v-icon>
            </v-btn>
          </v-subheader>
          <history-line
            class="nowrap"
            :key="index"
            v-for="(entry, index) in entries"
            :item="entry"
          />
        </v-list>
        <v-pagination
          class="mt-4"
          v-model="computedPageV5"
          :length="computedTotalPageV5"
          :total-visible="6"
          v-if="computedTotalPageV5 > 1"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import sidePanelController from '@/sidePanelController'

const pageSizeHistoV5 = 10

export default {
  components: {
    HistoryLine: () => import('./history-line')
  },
  data () {
    return {
      history: [],
      http: this.$http().debounced(),
      nextPage: null,
      processingQuery: false,
      computedTotalPageV5: 0,
      historyV5: [],
      pageV5: 0,
      selectedPanel: 0,
      showTabs: false
    }
  },
  computed: {
    computedPageV5: {
      get () {
        return this.pageV5 || 1
      },
      set (v) {
        if (this.pageV5 !== v) {
          this.pageV5 = v
          this.queryV5(v)
        }
      }
    },
    groupedHistory () {
      return this.lodash.groupBy(this.history, h => {
        const day = this.moment().startOf('day').diff(this.moment(h.date).startOf('day'), 'day')
        switch (day) {
          case 0: return this.$t('t.Today')
          case 1: return this.$t('t.Yesterday')
          default: return this.$t('t.Earlier')
        }
      })
    },
    groupedHistoryV5 () {
      return this.lodash.groupBy(this.historyV5, h => {
        const day = this.moment().startOf('day').diff(this.moment(h.date).startOf('day'), 'day')
        switch (day) {
          case 0: return this.$t('t.Today')
          case 1: return this.$t('t.Yesterday')
          default: return this.$t('t.Earlier')
        }
      })
    }
  },
  methods: {
    clear () {
      this.history = []
      this.nextPage = null
    },
    openInSidePanel () {
      sidePanelController.cacheType = 'TestingHistory'
      sidePanelController.documentId = this.documentId ?? this.$route.params.id
      sidePanelController.open()
    },
    async queryV5 (page) {
      if (!this?.tab?.documentId || !this?.tab?.documentType) {
        return
      }
      try {
        this.processingQuery = true

        page = (page || 1) - 1
        const docType = this.tab.documentType.replace('account-infos', 'accounts')
        if (docType === 'accounts') {
          const requestHistoV5 = await this.http.get(`/core/v6/accounts/${this.tab.documentId}/history-v5`, { params: { page: page, pageSize: pageSizeHistoV5 } })
          this.computedTotalPageV5 = Math.ceil((requestHistoV5.data.count ?? 0) / pageSizeHistoV5)
          this.historyV5 = requestHistoV5.data.items
        }

        this.$triggerResize()
      } catch (e) {
        if (e !== 'Debounced') {
          throw e
        }
      } finally {
        this.processingQuery = false
      }
    },
    async query (nextPage, pageSize = 10) {
      if (!this?.tab?.documentId || !this?.tab?.documentType || (this.history.length && !nextPage)) {
        return
      }
      try {
        this.processingQuery = true

        const docType = this.tab.documentType.replace('account-infos', 'accounts').replace('account-contacts', 'users').replace(/.*message.*/, 'messages')

        this.showTabs = docType === 'accounts'

        const response = await this.http.get(`/core/v6/histories/${docType}/${this.tab.documentId}`, { params: { nextPage: this.nextPage, pageSize: pageSize } })
        this.nextPage = response.data.nextPage

        response.data.items.forEach(e => this.history.push(e))
        this.$triggerResize()
      } catch (e) {
        if (e !== 'Debounced') {
          throw e
        }
      } finally {
        this.processingQuery = false
      }
    }
  },
  watch: {
    tab: {
      deep: true,
      immediate: true,
      handler (v) {
        if (v?.documentId && v?.documentType) {
          this.query()
        }
      }
    },
    selectedPanel: {
      handler (v) {
        if (v === 0) {
          this.historyV5 = []
          this.pageV5 = 0
          this.query()
        }
        if (v === 1) {
          this.clear()
          this.pageV5 = 0
          this.queryV5()
        }
      }
    }
  },
  props: {
    tab: {
      type: Object,
      required: true
    },
    canOpenSidePanel: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.history-menu
  max-height 650px
  overflow-y auto
</style>
